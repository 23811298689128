import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { useSiteMetadata } from '../hooks/use-site-metadata'
import logo from '../../static/images/logo_mini.png';

const Header = styled.header`
  background: ${props => props.theme.colors.primary};
  width: 100%;
  padding: 1.5em 0;
`
const Nav = styled.nav`
  display:flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: ${props => props.theme.sizes.maxWidth};
  margin: 0 auto;
  padding: 0 1.5em;
  img {
    display:flex;
    height: 50px;
    width:auto;
    margin-right:20px;
  }
  
  ul {
    width:90%;
    display: flex;
    justify-content: space-between;
  }

  li {
    display: inline-block;
    margin-left: 1em;
    width:20%;
    &:first-of-type {
      position: relative;
      margin: 0;
      flex-basis: 100%;
    }
  }

  a {
    text-decoration: none;
    color: DarkGray;
    font-weight: 600;
    transition: all 0.2s;
    border-bottom: 2px solid ${props => props.theme.colors.text};
    &:hover {
      color: white;
    }
  }
`

const activeLinkStyle = {
  color: 'white',
}

const Menu = () => {
  const { menuLinks } = useSiteMetadata()
  return (
    <Header>
      <Nav>
          <a href="https://www.conity-solutionspro.com/" target="_blank"  rel="noopener noreferrer" className="navbar-item">
              <figure className="image">
                  <img src={logo}  alt="Logo Conity" className="logo"/>
              </figure>
          </a>
          <ul>
          {menuLinks.map(link => (
            <li key={link.name}>
              <a href={link.slug}  target='_blank' rel='noreferrer noopener'>
                {link.name}
              </a>
            </li>
          ))}
        </ul>
      </Nav>
    </Header>
  )
}

export default Menu
